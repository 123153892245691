import React from 'react';
import Contact from '../Components/Contact';

const contactContainer = () => {
  return (
    <div>
      <Contact />
    </div>
  );
};

export default contactContainer;
