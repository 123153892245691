import React from 'react';
import About from '../Components/About';

function AboutContainer() {
  return (
    <div>
      <About />
    </div>
  );
}

export default AboutContainer;
