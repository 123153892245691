import React from 'react';
import Projects from '../Components/Projects/Projects';

const projectContainer = () => {
  return (
    <div>
      <Projects />
    </div>
  );
};

export default projectContainer;
