import React from 'react';

const Tech = ({ one, two, three, four }) => {
  return (
    <div>
      <ul>
        <li>{one}</li>
        <li>{two}</li>
        <li>{three}</li>
        <li>{four}</li>
      </ul>
    </div>
  );
};

export default Tech;
